export function getRecordType (record) {
    if (!record) {
        return null;
    }

    if (record?.ives_request_status) {
        return 'ives-request';
    } else {
        return 'expanded-ives-request';
    }
}