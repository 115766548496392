import {Box, Grid, Typography} from "@mui/material";
import {CheckboxGroupInput, maxLength, minLength, ReferenceArrayInput, required, SelectInput, TextInput} from "react-admin";
import React, {useEffect} from "react";
import {RequestCreateFormInput} from "./RequestCreateFormInput";
import {useForm, useFormContext} from "react-hook-form";
import {ToolTip} from "../../../../Components/ToolTip";
import {TrackingNumberStringForIndustry} from "../../../../Components/Functions/Labels";

/** @var retryData.ives_request_type_id **/
/** @var retryData.expanded_ives_request_type_id **/
/** @var retryData.ives_request_form_id **/
/** @var retryData.expanded_ives_request_form_id **/
/** @var retryData.tracking_number **/
/** @var retryData.expanded_tracking_number **/

function getLastTenYears() {
    let years = [];
    const currentDate = new Date();
    for (let i = 1; i <= 10; i++) {
        const year = currentDate.getFullYear() - i;

        years.push({id: year, name: year, checked: true});
    }

    return years;
}

export const RequestCreateRequestInput = ({ type, retryData }) => {
    const { setValue } = useFormContext();
    const { clearErrors } = useForm();
    const validateTrackingNumber = [minLength(1), maxLength(256)];

    useEffect(() => {
        if (retryData) {
            const requestTypeIdFieldName = type === 'ives-request' ? 'ives_request_type_id' : 'expanded_ives_request_type_id';
            const requestFormIdFieldName = type === 'ives-request' ? 'ives_request_form_id' : 'expanded_ives_request_form_id';
            setTimeout(() => setValue(requestTypeIdFieldName, type === 'ives-request' ? retryData.ives_request_type_id : retryData.expanded_ives_request_type_id));
            setTimeout(() => setValue(requestFormIdFieldName, type === 'ives-request' ? retryData.ives_request_form_id : retryData.expanded_ives_request_form_id));
            setTimeout(() => setValue('tracking_number', type === 'ives-request' ? retryData.tracking_number : retryData.expanded_tracking_number));
            clearErrors();
        }

    }, [setValue, retryData, clearErrors, type]);

    let defaultValue = [];

    if (retryData) {
        const years = type === 'ives-request' ? retryData.ives_request_years : retryData.expanded_ives_request_years;
        defaultValue = years.map((year) => (year.year))
    }

    const years = getLastTenYears();

    const typeLabel = type === 'ives-request' ? 'IVES Requests' : 'Expanded IVES Requests';
    const typeMaxYears = type === 'ives-request' ? 4 : 10;
    const validateYears =
        [
            required('You must select at least one year.'),
            maxLength(typeMaxYears, typeLabel + ' support a maximum of ' + typeMaxYears + ' years.')
        ];

    function onAutoFocus (event) {
        if (event.target.autocomplete) {
            event.target.autocomplete = "whatever";
        }
    }

    const requestTypeSource = type === 'ives-request' ? 'ives_request_type_id' : 'expanded_ives_request_type_id';
    const requestFormSource = type === 'ives-request' ? 'ives_request_form_id' : 'expanded_ives_request_form_id';
    const requestYearsSource = type === 'ives-request' ? 'ives_request_years' : 'expanded_ives_request_years';
    return (
        <Grid item md={3} sm={6} xs={12}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                <Grid item>
                    <Box>
                        <Typography variant="h6">Request</Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <ReferenceArrayInput name="RequestType" source={requestTypeSource} reference="ives-request-types" label="Type" perPage={1000} sort={{ field: "name", order: "ASC" }}>
                            <SelectInput optionText="name" helperText={false} name={requestTypeSource} validate={[required()]} label="Type" sx={{width: "100%"}} />
                        </ReferenceArrayInput>
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <RequestCreateFormInput source={requestFormSource} requestFormTypeSelect={requestTypeSource} />
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <CheckboxGroupInput
                            source={requestYearsSource}
                            helperText={false}
                            label="Years"
                            validate={validateYears}
                            choices={years}
                            defaultValue={defaultValue}
                            sx={{
                                [`& .MuiFormControlLabel-label`]: {
                                    fontSize: 13
                                }
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item paddingTop={1}>
                    <Box>
                        <Typography variant="h6">
                            {TrackingNumberStringForIndustry()}
                            <ToolTip
                                title="Internal Identification"
                                text="This is an optional internal identification. Whether it's a tracking number, a loan number, a candidate identification, etc., this field allows you to save your internal number associated with this request."
                            />
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <TextInput source="tracking_number" helperText={false} validate={validateTrackingNumber} name="tracking_number" label={TrackingNumberStringForIndustry()} sx={{width: "100%"}} autoComplete="off" onFocus={onAutoFocus} />
                </Grid>
            </Grid>
        </Grid>
    );
};