import {Confirm, useRecordContext, useRedirect} from "react-admin";
import React, {useState} from "react";
import {Button} from "ra-ui-materialui";
import RedoIcon from '@mui/icons-material/Redo';
import {getRecordType} from "../../../../Components/Functions/Record";

/** @var record.ives_request_status **/
/** @var record.expanded_ives_request_status **/

export const RetryFailedButton = () => {
    const record = useRecordContext();
    const redirect = useRedirect();
    const [open, setOpen] = useState(false);

    let isDisabled = false;

    if (
        !record ||
        (record?.ives_request_status && (record.ives_request_status !== "Failed Submission" && record.ives_request_status !== "Expired")) ||
        (record?.expanded_ives_request_status && (record.expanded_ives_request_status !== "Failed Submission" && record.expanded_ives_request_status !== "Expired"))
    ) {
        isDisabled = true;
    }

    const recordType = getRecordType(record);

    const Retry = () => {
        const retryType = recordType === 'ives-request' ? 'retryIvesRequest' : 'retryExpandedIvesRequest';
        localStorage.setItem(retryType, JSON.stringify(record));
        const redirectTo = recordType === 'ives-request' ? '/user/ives-requests/create' : '/user/expanded-ives-requests/create' ;

        redirect(redirectTo);
    }

    const HandleClick = () => setOpen(true);

    const HandleDialogClose = () => setOpen(false);

    return (
        <>
            <Button
                label='Retry'
                disabled={isDisabled}
                onClick={HandleClick}
            >
                <RedoIcon />
            </Button>
            <Confirm
                confirm={'Retry'}
                fullWidth={false}
                isOpen={open}
                title={`Retry Request?`}
                content={
                <>
                    This will be copied to a new request where you can change any necessary data before retrying. 
                </>}
                onConfirm={Retry}
                onClose={HandleDialogClose}
            />
        </>
    )
}