import {
    Show,
    List,
    SearchInput,
    useRecordContext,
    FunctionField,
    SimpleList,
    useShowController,
    SelectColumnsButton,
    CreateButton,
    useGetIdentity,
} from "react-admin";
import { SimpleShowLayout, TextField, DateField } from "react-admin";
import React from "react";
import {Box, Grid, Typography, useMediaQuery} from "@mui/material";
import {IvesRequestStatusFilterSelectInput} from "../../../Components/Filters/IvesRequestStatusFilterSelectInput";
import {IvesRequestFormFilterSelectInput} from "../../../Components/Filters/IvesRequestFormFilterSelectInput";
import {formatSocialSecurityNumber, parseSocialSecurityNumber, renderPartialSsnFromRecord, renderSsnFromRecord} from "../../../Components/Functions/SocialSecurityNumber";
import {EmptyList} from "../../../Components/EmpyList";
import {renderIvesClientPhoneNumber} from "../../../Components/Functions/PhoneNumber";
import {renderIvesClientAddress, renderTaxpayerCurrentAddress, renderTaxpayerPreviousAddress} from "../../../Components/Functions/Address";
import {SendTaxpayerEmailNotificationButton} from "./Components/SendTaxpayerEmailNotificationButton";
import {RequestStatusField} from "./Components/RequestStatusField";
import {renderYearsForListFromRecord, renderYearsFromRecord} from "../../../Components/Functions/Years";
import {renderFormFromRecord} from "../../../Components/Functions/Form";
import {renderTaxpayerNameFromRecord} from "../../../Components/Functions/TaxpayerName";
import {PageTitle} from "../../../Components/PageTitle";
import {RetryFailedButton} from "./Components/RetryFailedButton";
import {usePermissions} from "ra-core";
import {IvesRequestUserFilterSelectInput} from "../../../Components/Filters/IvesRequestUserFilterSelectInput";
import {IvesRequestBranchFilterSelectInput} from "../../../Components/Filters/IvesRequestBranchFilterSelectInput";
import {Accordion, AccordionSummary, AccordionDetails} from '../../../Components/Accordion.js';
import {DatagridConfigurable} from "../../../Components/DatagridConfigurable";
import {TrackingNumberStringForIndustry} from "../../../Components/Functions/Labels";
import {TopToolbar} from "../../../Components/TopToolbar";
import {Item} from "../../../Components/Items";
import {TaxpayerEmailNotifications} from "../../../Components/IvesRequestTaxpayerEmailNotifications";
import {Results} from "./Components/Results";
import {RequestCreate} from "./Components/RequestCreate";

const PERMISSION_BRANCH_ADMINISTRATOR = 'branch-administrator';
const PERMISSION_INSTITUTION_ADMINISTRATOR = 'institution-administrator';

/** @var record.taxpayer_ssn **/
/** @var record.taxpayer_street_name **/
/** @var record.taxpayer_city_name **/
/** @var record.taxpayer_state_abbreviation **/
/** @var record.taxpayer_zip **/
/** @var record.taxpayer_previous_street_name **/
/** @var record.taxpayer_previous_city_name **/
/** @var record.taxpayer_previous_state_abbreviation **/
/** @var record.taxpayer_previous_zip **/
/** @var record.expanded_ives_request_status **/
/** @var record.expanded_ives_request_years **/
/** @var record.ives_client **/
/** @var record.ives_client.street_name **/
/** @var record.ives_client.city_name **/
/** @var record.ives_client.state_abbreviation **/
/** @var record.ives_client.zip **/
/** @var record.ives_requests **/
/** @var record.expanded_ives_request_status **/
/** @var record.expanded_ives_request_status.name **/
/** @var record.expanded_ives_request_results_formatted **/

const ListActions = ({ isSmall }) => (
    <TopToolbar>
        {(isSmall) ? (<></>) : (<SelectColumnsButton />)}
        <CreateButton />
    </TopToolbar>
);

export const UserExpandedIvesRequestList = () => {
    const { permissions } = usePermissions();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    function hasPermission(permissionToFind){
        const result = permissions.find((permission) => permission === permissionToFind);

        return result !== undefined;
    }

    return (
        <List
            filters={ListFilters()}
            actions={<ListActions isSmall={isSmall} />}
            title={<PageTitle label="Expanded IVES Requests" />}
            resource="user/expanded-ives-requests"
            sort={{ field: 'created_at', order: 'DESC' }}
            empty={<EmptyList resourceLabel="Expanded IVES Requests" createButton={true} />}
            queryOptions={{refetchOnWindowFocus: true}}
        >
            {isSmall ? (
                <SimpleList
                    hasBulkActions={false}
                    linkType="show"
                    primaryText={<SimpleListLabel />}
                    secondaryText={renderTaxpayerNameFromRecord}
                />
            ) : (
                <DatagridConfigurable bulkActionButtons={false} rowClick="show" id='expanded-ives-request-table'>
                    <TextField source="id" label="ID" />
                    <FunctionField
                        source="tracking_number"
                        label={TrackingNumberStringForIndustry()}
                        sortable={false}
                        render={(record) => {
                            if (!("tracking_number" in record) || !record.tracking_number) {
                                return "";
                            }

                            return <span>{record.tracking_number.substring(0,20)}{record.tracking_number.length > 20 ? "..." : ""}</span>;
                        }}
                    />
                    { (hasPermission(PERMISSION_INSTITUTION_ADMINISTRATOR)) ? (
                        <TextField source="user.branch.name" label="Branch" sortable={false} />
                    ) : (<></>)}
                    { (hasPermission(PERMISSION_INSTITUTION_ADMINISTRATOR) || hasPermission(PERMISSION_BRANCH_ADMINISTRATOR)) ? (
                        <TextField source="user.name" label="User" sortable={false} />
                    ) : (<></>)}
                    <TextField source="ives_client.name" label="Client" sortable={false} />
                    <FunctionField render={renderTaxpayerNameFromRecord} label="Taxpayer" sortable={false} />
                    <FunctionField render={renderPartialSsnFromRecord} label="SSN" sortable={false} />
                    <TextField source="expanded_ives_request_type.name" label="Type" sortable={false} />
                    <FunctionField render={renderFormFromRecord} label="Form" sortable={false} />
                    <FunctionField render={renderYearsForListFromRecord} label="Years" sortable={false} />
                    <TextField source="expanded_ives_request_status" label="Status" sortable={false} />
                    <DateField showTime={true} source="created_at" label="Created" />
                </DatagridConfigurable>
            )}
        </List>
    );
};

const SimpleListLabel = () => {
    const record = useRecordContext();

    if (!record) {
        return '';
    }

    return (
        <Typography key={Math.random()} variant="body2">
            {record.taxpayer_ssn + " - "}
            {
                record.expanded_ives_request_years.map(
                    t => <span key={Math.random()}>{t.year}</span>
                ).reduce(
                    (prev, curr) => [prev, ', ', curr]
                )
            }
        </Typography>
    );
};

const ListFilters = () => {
    const { permissions } = usePermissions();
    function hasPermission(permissionToFind){
        const result = permissions.find((permission) => permission === permissionToFind);

        return result !== undefined;
    }

    const listFilters = [
        <SearchInput
            placeholder="SSN"
            source="taxpayer_ssn"
            name="taxpayer_ssn"
            alwaysOn={true}
            format={formatSocialSecurityNumber}
            parse={parseSocialSecurityNumber}
            sx={{ width: 165 }}
            key={Math.random()}
        />,
        <SearchInput
            placeholder={TrackingNumberStringForIndustry()}
            source="tracking_number"
            name="tracking_number"
            alwaysOn={true}
            sx={{ width: 165 }}
            key={Math.random()}
        />,
        <IvesRequestFormFilterSelectInput source="expanded_ives_request_form_id" label="FORM" alwaysOn={true} key={Math.random()} />,
        <IvesRequestStatusFilterSelectInput source="expanded_ives_request_status_id" label="STATUS" alwaysOn={true} key={Math.random()} />,
    ];

    if (hasPermission(PERMISSION_INSTITUTION_ADMINISTRATOR)) {
        listFilters.push(<IvesRequestBranchFilterSelectInput label="BRANCH" alwaysOn={true} key={Math.random()} />);
    }

    if (hasPermission(PERMISSION_INSTITUTION_ADMINISTRATOR) || hasPermission(PERMISSION_BRANCH_ADMINISTRATOR)) {
        listFilters.push(<IvesRequestUserFilterSelectInput label="USER" alwaysOn={true} key={Math.random()} />);
    }

    return listFilters;
}

export const UserExpandedIvesRequestShow = () => {
    const record = useShowController()?.record;
    const { isFetching } = useShowController();
    const { permissions } = usePermissions();
    function hasPermission(permissionToFind){
        const result = permissions.find((permission) => permission === permissionToFind);

        return result !== undefined;
    }

    const ShowWrapper = ({ children }) => (
        <Box sx={{ width: "100%" }}>
            {children}
        </Box>
    );

    const showResults = !(isFetching || !record || record.expanded_ives_request_status !== 'Complete');

    return (
        <Show title={<ShowTitle />} actions={<ShowActions />} resource="user/expanded-ives-requests" component={ShowWrapper} >
            <Accordion defaultExpanded>
                <AccordionSummary >
                    <Typography>Request</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1} direction="row" justifyContent="space-evenly" alignItems="stretch" sx={{padding: 1}}>
                        <Grid item xl={3} lg={6} xs={12}>
                            <Item>
                                <Box sx={{padding: 1}}>
                                    <Typography variant="h6">Client</Typography>
                                </Box>
                                <SimpleShowLayout>
                                    <TextField source="ives_client.name" label="Name" />
                                    <FunctionField render={renderIvesClientPhoneNumber} label="Phone" />
                                    <FunctionField render={renderIvesClientAddress} label="Address" component="pre" />
                                </SimpleShowLayout>
                            </Item>
                        </Grid>
                        <Grid item xl={3} lg={6} xs={12}>
                            <Item>
                                <Box sx={{padding: 1}}>
                                    <Typography variant="h6">Taxpayer/Applicant</Typography>
                                </Box>
                                <SimpleShowLayout>
                                    <FunctionField render={renderSsnFromRecord} label="SSN" />
                                    <FunctionField render={renderTaxpayerNameFromRecord} label="Taxpayer" />
                                    <FunctionField render={renderTaxpayerCurrentAddress} label="Address" component="pre" />
                                    {(record?.taxpayer_previous_street_name) ? (
                                        <FunctionField render={renderTaxpayerPreviousAddress} label="Additional Address" component="pre" />
                                    ) : (<></>)}
                                </SimpleShowLayout>
                            </Item>
                        </Grid>
                        <Grid item xl={3} lg={6} xs={12}>
                            <Item>
                                <TaxpayerEmailNotifications />
                            </Item>
                        </Grid>
                        <Grid item xl={3} lg={6} xs={12}>
                            <Item>
                                <Box sx={{padding: 1}}>
                                    <Typography variant="h6">Request</Typography>
                                </Box>
                                <SimpleShowLayout>
                                    { (hasPermission(PERMISSION_INSTITUTION_ADMINISTRATOR)) ? (
                                        <TextField source="user.branch.name" label="Branch" sortable={false} />
                                    ) : (<></>)}
                                    { (hasPermission(PERMISSION_INSTITUTION_ADMINISTRATOR) || hasPermission(PERMISSION_BRANCH_ADMINISTRATOR)) ? (
                                        <TextField source="user.name" label="User" sortable={false} />
                                    ) : (<></>)}
                                    <TextField source="expanded_ives_request_channel" label="Channel" />
                                    <TextField source="expanded_ives_request_type.name" label="Type" />
                                    <TextField source="expanded_ives_request_form.name" label="Form" />
                                    <TextField source="tracking_number" label={TrackingNumberStringForIndustry()} />
                                    <FunctionField render={renderYearsFromRecord} label="Years" />
                                    <RequestStatusField />
                                    <DateField showTime={true} source="created_at" label="Created" />
                                </SimpleShowLayout>
                            </Item>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion disabled={!showResults} defaultExpanded={showResults}>
                <AccordionSummary >
                    <Typography>Results</Typography>
                </AccordionSummary>
                {
                    (showResults) ? (
                        <AccordionDetails>
                            <Results />
                        </AccordionDetails>
                    ) : (<></>)
                }
            </Accordion>
        </Show>
    )
};

const ShowActions = () => {
    const record = useRecordContext();
    const { permissions } = usePermissions();
    const { identity } = useGetIdentity();
    function hasPermission(permissionToFind){
        const result = permissions.find((permission) => permission === permissionToFind);

        return result !== undefined;
    }

    const shouldShowButtons =
        (!hasPermission(PERMISSION_INSTITUTION_ADMINISTRATOR) && !hasPermission(PERMISSION_BRANCH_ADMINISTRATOR)) ||
        record?.user?.id === identity?.id;

    return (
        <TopToolbar backButton={true}>
            {
                (shouldShowButtons) ? (
                    <>
                        <RetryFailedButton role="user" />
                        <SendTaxpayerEmailNotificationButton role="user" />
                    </>
                ) : (<></>)
            }
        </TopToolbar>
    );
}

const ShowTitle = () => {
    const record = useRecordContext();

    if (!record) {
        return '';
    }

    return 'Expanded IVES Request #' + record.id;
}

export const UserExpandedIvesRequestCreate = (<RequestCreate type="expanded-ives-request" />);