import {useRecordContext} from "react-admin";
import React from "react";
import {Box, Button} from "@mui/material";
import {CustomTab, CustomTabPanel, CustomTabProps, CustomTabs} from "../../../../Components/Tab";
import {Item} from "../../../../Components/Items";

/** @var record.ives_request_results_merged_pdf **/
/** @var record.expanded_ives_request_results_merged_pdf **/
/** @var record.ives_request_results_merged_html **/
/** @var record.expanded_ives_request_results_merged_html **/

export const Results = () => {
    const record = useRecordContext();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const pdfFilename = 'result.pdf';
    const pdfString = record.ives_request_results_merged_pdf ?? record.expanded_ives_request_results_merged_pdf;

    const htmlFilename = 'result.html';
    const htmlString = btoa(record.ives_request_results_merged_html ?? record.expanded_ives_request_results_merged_html);

    return (
        <Box sx={{ width: '100%', backgroundColor: 'background.paper' }}>
            <CustomTabs value={value} onChange={handleChange} >
                <CustomTab label="Report" {...CustomTabProps(0)} />
                <CustomTab label="Downloads" {...CustomTabProps(1)} />
            </CustomTabs>
            <Box>
                <CustomTabPanel value={value} index={0} >
                    <MergedIvesResultView />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <a download={pdfFilename} href={`data:application/pdf;base64,${pdfString}`} ><Button variant="outlined">Download PDF</Button></a><br/><br/>
                    <a download={htmlFilename} href={`data:text/plain;base64,${htmlString}`} ><Button variant="outlined">Download HTML</Button></a><br/><br/>
                </CustomTabPanel>
            </Box>
        </Box>
    )
}

const MergedIvesResultView = () => {
    const record = useRecordContext();

    if (!record) {
        return '';
    }

    const results_merged_pdf = record.ives_request_results_merged_pdf ?? record.expanded_ives_request_results_merged_pdf;

    if (!results_merged_pdf) {
        return '';
    }

    return (
        <Item>
            <embed
                key={Math.random()}
                style={{ height: 500, overflow: 'scroll' }}
                src={`data:application/pdf;base64,${results_merged_pdf}`}
                type="application/pdf" width="100%"
            ></embed>
        </Item>
    );
}