import {Confirm, SaveButton, Toolbar, useCreate, useNotify, useRedirect, useResourceContext} from "react-admin";
import React, {useEffect, useState} from "react";
import {useFormContext, useFormState} from "react-hook-form";
import {Backdrop, CircularProgress, Grid} from "@mui/material";
import {RequestReviewContent} from "./RequestReviewContent";

const CreateSaveButton = ({ type, confirm, setConfirm }) => {
    const redirect = useRedirect();
    const notify = useNotify();
    const [open, setOpen] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const resource = useResourceContext();
    const { getValues } = useFormContext()
    const { isValid } = useFormState();

    const [create, { isLoading }] = useCreate();

    const showRequestReview = e => {
        let numberOfPreviousAddressInputsPopulated = 0;

        if (getValues().taxpayer_previous_street_name) { numberOfPreviousAddressInputsPopulated++; }
        if (getValues().taxpayer_previous_city_name) { numberOfPreviousAddressInputsPopulated++; }
        if (getValues().taxpayer_previous_state_abbreviation) { numberOfPreviousAddressInputsPopulated++; }
        if (getValues().taxpayer_previous_zip) { numberOfPreviousAddressInputsPopulated++; }

        if (numberOfPreviousAddressInputsPopulated !== 0 && numberOfPreviousAddressInputsPopulated !== 4) {
            e.preventDefault();
            notify('Previous address incomplete', { type: 'error' });
            return;
        }

        if (!getValues().taxpayer_street_name) {
            e.preventDefault();
            notify('Address incomplete', { type: 'error' });
            return;
        }

        if (!isValid) {
            return;
        }

        e.preventDefault();
        setOpen(true);
    }

    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        setBackdropOpen(true);
        const creationData = type === 'ives-request' ? {ives_request: getValues()} : {expanded_ives_request: getValues()};
        const successMessage = type === 'ives-request' ? 'IVES Request Created' : 'Expanded IVES Request Created';
        create(
            resource,
            {data: creationData},
            {
                onSuccess: (data) => {
                    const redirectTo = '/' + resource + '/' + data.id + '/show';
                    redirect(redirectTo);
                    notify(successMessage, { type: 'success' });
                },
                onError: (data) => {
                    notify(data.toString(), { type: 'error' });
                },
                onSettled: () => {
                    setBackdropOpen(false);
                }
            }
        );
        setOpen(false);
    };

    useEffect(() => {
        if (confirm) {
            setConfirm(false)
            setOpen(true);
        }
    }, [confirm, setConfirm]);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs></Grid>
                <Grid item xs={2}>
                    <SaveButton
                        label="Next"
                        alwaysEnable={true}
                        onClick={showRequestReview}
                    />
                </Grid>
                <Grid item xs></Grid>
            </Grid>
            <Confirm
                confirm={"Submit"}
                fullWidth={true}
                maxWidth={"lg"}
                isOpen={open}
                loading={isLoading}
                title={`Review Request`}
                content={<RequestReviewContent type={type} />}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

export const RequestCreateToolbar = ({ type, confirm, setConfirm }) => (
    <Toolbar>
        <CreateSaveButton type={type} confirm={confirm} setConfirm={setConfirm} />
    </Toolbar>
);