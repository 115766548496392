import React, {useEffect, useState} from "react";
import {Create, SimpleForm} from "react-admin";
import {PageTitle} from "../../../../Components/PageTitle";
import {RequestCreateToolbar} from "./RequestCreateToolbar";
import {Grid} from "@mui/material";
import {RequestCreateIvesClientInput} from "./RequestCreateIvesClientInput";
import {RequestCreateTaxpayerInput} from "./RequestCreateTaxpayerInput";
import {RequestCreateTaxpayerNotificationInput} from "./RequestCreateTaxpayerNotificationInput";
import {RequestCreateRequestInput} from "./RequestCreateRequestInput";

export const RequestCreate = ({ type }) => {
    const retryType = type === 'ives-request' ? 'retryIvesRequest' : 'retryExpandedIvesRequest';
    let [retryData] = React.useState(JSON.parse(localStorage.getItem(retryType))) ?? null;
    const [confirm, setConfirm] = useState(false);

    useEffect(() => {
        localStorage.removeItem('retryIvesRequest');
        localStorage.removeItem('retryExpandedIvesRequest');
    }, []);


    const titleLabel = type === 'ives-request' ? 'Create IVES Request' : 'Create Expanded IVES Request';

    return (
        <Create title={<PageTitle label={titleLabel} />}>
            <SimpleForm toolbar={<RequestCreateToolbar type={type} confirm={confirm} setConfirm={setConfirm} />} mode="onBlur" reValidateMode="onBlur" onSubmit={() => {setConfirm(true);}} >
                <Grid container columnSpacing={{xs: 1, sm: 2, md: 3}} lineHeight="1.0" direction="row" justifyContent="flex-start" alignItems="stretch" >
                    <RequestCreateIvesClientInput type={type} retryData={retryData} />
                    <RequestCreateTaxpayerInput type={type} retryData={retryData} />
                    <RequestCreateTaxpayerNotificationInput type={type} retryData={retryData} />
                    <RequestCreateRequestInput type={type} retryData={retryData} />
                </Grid>
            </SimpleForm>
        </Create>
    );
};