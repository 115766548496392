import {FunctionField, Labeled, useRecordContext} from "react-admin";
import React from "react";
import {ToolTip} from "../../../../Components/ToolTip";
import {IvesRequestStatusTooltipText} from "../../../../Components/IvesRequestStatusTooltipText";
import {getDatagridRowTextColor} from "../../../../Components/Functions/DatagridRowSx";

/** @var record.ives_request_status **/
/** @var record.expanded_ives_request_status **/

export const RequestStatusField = () => {
    const record = useRecordContext();

    if (!record || (!record.ives_request_status && !record.expanded_ives_request_status)) {
        return '';
    }

    const status = record.ives_request_status ?? record.expanded_ives_request_status;
    const tooltipText = IvesRequestStatusTooltipText[status];

    return (
        <>
            <Labeled>
                <FunctionField 
                    render={(record) => {return record.ives_request_status ?? record.expanded_ives_request_status;}}
                    label="Status"
                    component="pre"
                    sx={{ color: getDatagridRowTextColor(record), fontWeight: 'bold' }}
                />
            </Labeled>
            <ToolTip
                title={status}
                text={tooltipText}
            />
        </>
    );
};