import {Button, Chip, Grid, Typography} from "@mui/material";
import {useFormContext} from "react-hook-form";
import {useGetList, useGetOne} from "react-admin";
import {formatSocialSecurityNumber} from "../../../../Components/Functions/SocialSecurityNumber";
import React from "react";
import {useMutation} from "react-query";
import CheckIcon from "@mui/icons-material/Check";
import dataProvider from "../../../../Providers/dataProvider";
import {TrackingNumberStringForIndustry} from "../../../../Components/Functions/Labels";
import {ItemCenter} from "../../../../Components/Items";

const GetIvesClients = (ivesClientId) => {
    const { data } = useGetOne(
        'user/ives-clients',
        { "id": ivesClientId }
    );

    return data?.name ?? '';
}

const GetShouldNotifyTaxpayersViaEmail = () => {
    const { data } = useGetOne(
        'user/ives-settings',
        { "id": 1 }
    );

    return data?.should_notify_taxpayers_via_email ?? true;
}

export const RequestReviewContent = ({ type }) => {
    const { getValues } = useFormContext();
    const reviewData = getValues();

    const clientName = GetIvesClients(reviewData.ives_client_id);
    const shouldNotifyTaxpayersViaEmail = GetShouldNotifyTaxpayersViaEmail();

    const ivesRequestFormId = type === 'ives-request' ? reviewData.ives_request_form_id : reviewData.expanded_ives_request_form_id;
    const ivesRequestYears = type === 'ives-request' ? reviewData.ives_request_years : reviewData.expanded_ives_request_years;

    const xlColumnSize = reviewData.taxpayer_email ? 3 : 4;
    
    return (
        <Grid container spacing={2}>
            <Grid item xl={xlColumnSize} lg={6} xs={12} sx={{padding: 2}}>
                <Grid container spacing={1}>
                    <Grid item xs={12} textAlign={"center"}>
                        <Typography variant="h6">Client</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ItemCenter>
                            <Typography variant="caption" display="block" noWrap>Name: </Typography>
                            <Typography variant="button" >{clientName}</Typography>
                        </ItemCenter>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xl={xlColumnSize} lg={6} xs={12} sx={{padding: 1}}>
                <Grid container spacing={1}>
                    <Grid item xs={12} textAlign={"center"}>
                        <Typography variant="h6">Taxpayer/Applicant</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ItemCenter>
                            <Typography variant="caption" display="block" noWrap>Name: </Typography>
                            <Typography variant="button" noWrap>
                                {reviewData.taxpayer_first_name} {reviewData.taxpayer_middle_initial ? reviewData.taxpayer_middle_initial + ' ' : ''}{reviewData.taxpayer_last_name}
                            </Typography>
                        </ItemCenter>
                    </Grid>
                    <Grid item xs={12}>
                        <ItemCenter>
                            <Typography variant="caption" display="block" noWrap>SSN: </Typography>
                            <Typography variant="button" noWrap>{formatSocialSecurityNumber(reviewData.taxpayer_ssn)}</Typography>
                        </ItemCenter>
                    </Grid>
                    <Grid item xs={12}>
                        <ItemCenter>
                            <Typography variant="caption" display="block" noWrap>Address: </Typography>
                            <Typography variant="button" display="block" noWrap>
                                {reviewData.taxpayer_street_name}
                            </Typography>
                            <Typography variant="button" noWrap>
                                {reviewData.taxpayer_city_name + ', ' + reviewData.taxpayer_state_abbreviation + ' ' + reviewData.taxpayer_zip}
                            </Typography>
                        </ItemCenter>
                    </Grid>
                    {(reviewData.taxpayer_previous_street_name || reviewData.taxpayer_previous_city_name || reviewData.taxpayer_previous_state_abbreviation || reviewData.taxpayer_previous_zip) ? (
                        <Grid item xs={12}>
                            <ItemCenter>
                                <Typography variant="caption" display="block" noWrap>Additional Address: </Typography>
                                <Typography variant="button" display="block" noWrap>
                                    {reviewData.taxpayer_previous_street_name}
                                </Typography>
                                <Typography variant="button" noWrap>
                                    {reviewData.taxpayer_previous_city_name + ', ' + reviewData.taxpayer_previous_state_abbreviation + ' ' + reviewData.taxpayer_previous_zip}
                                </Typography>
                            </ItemCenter>
                        </Grid>
                    ) : (<Grid item xs={12}>
                            <ItemCenter>
                                <Typography variant="caption" display="block" noWrap>Additional Address: </Typography>
                                <Typography variant="button text" display="block" sx={{ color: "#cf4629", fontWeight: "bold" }}>
                                    Did you know that inputting an additional address increases the chances of your request being approved by the IRS?<br />
                                    If your taxpayer isn't 100% sure which address they used with their last tax filing, it is a good idea to enter
                                    an additional address.
                                </Typography>
                            </ItemCenter>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            { (reviewData.taxpayer_email) ? (
            <Grid item xl={xlColumnSize} lg={6} xs={12} sx={{padding: 1}}>
                <Grid container spacing={1}>
                    <Grid item xs={12} textAlign={"center"}>
                        <Typography variant="h6">Notification</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ItemCenter>
                            <Typography variant="caption" display="block" noWrap>Email: </Typography>
                            <Typography variant="button" noWrap>{reviewData.taxpayer_email}</Typography>
                        </ItemCenter>
                    </Grid>
                    { (!shouldNotifyTaxpayersViaEmail) ? 
                        (<EnableTaxpayerEmailNotificationsButton />) : 
                        (<></>)
                    }
                </Grid>
            </Grid>
                ) : (<></>)}
            <Grid item xl={xlColumnSize} lg={6} xs={12} sx={{padding: 1}}>
                <Grid container spacing={1}>
                    <Grid item xs={12} textAlign={"center"}>
                        <Typography variant="h6">Request</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ItemCenter>
                            <Typography variant="caption" display="block" noWrap>Form: </Typography>
                            <Typography variant="button">
                                <ReviewForm ivesRequestFormId={ivesRequestFormId} />
                            </Typography>
                        </ItemCenter>
                    </Grid>
                    <Grid item xs={12}>
                        <ItemCenter>
                            <Typography variant="caption" display="block" noWrap>Year(s): </Typography>
                            <Typography variant="button">
                                <ReviewYears ivesRequestYears={ivesRequestYears} />
                            </Typography>
                        </ItemCenter>
                    </Grid>
                    { (reviewData.tracking_number) ? (
                    <Grid item xs={12}>
                        <ItemCenter>
                            <Typography variant="caption" display="block" noWrap>{TrackingNumberStringForIndustry()}: </Typography>
                            <Typography variant="button" noWrap>{reviewData.tracking_number.substring(0,20)}{reviewData.tracking_number.length > 20 ? "..." : ""}</Typography>
                        </ItemCenter>
                    </Grid>
                    ) : (<></>)}
                </Grid>
            </Grid>
        </Grid>
    );
};

const ReviewForm = ({ivesRequestFormId}) => {
    const { data, isLoading, isError} = useGetList('ives-request-forms');

    if (isLoading || isError || !data) {
        return ''
    }

    const selectedIvesRequestForms = data.filter(
        item => {
            return item.id === ivesRequestFormId
        }
    );

    const forms = selectedIvesRequestForms.map(
        form => <Chip key={Math.random()} label={form.name} variant="outlined" />
    );

    return (
        <>
            {forms}
        </>
    );
}

const ReviewYears = ({ivesRequestYears}) => {
    const years = ivesRequestYears.map(
        year => <Chip key={Math.random()} label={year} variant="outlined" />
    );

    return (
        <>
            {years}
        </>
    );
}

const EnableTaxpayerEmailNotificationsButton = () => {
    const { mutate, isLoading, isSuccess } = useMutation(
        () => dataProvider.enableTaxpayerEmailNotifications()
    );

    let warningColor = 'red';
    let warningTitle = 'Warning';
    let warningLabel = 'Email notifications are currently disabled.';

    let buttonDisabled = false;
    let buttonLabel = 'Enable Now';
    let buttonStartIcon = <></>;

    if (isLoading) {
        buttonDisabled = true;
    }

    if (isSuccess) {
        warningColor = 'blue';
        warningTitle = 'Success';
        warningLabel = 'Email notifications are enabled.';
        buttonDisabled = true;
        buttonLabel = 'Enabled';
        buttonStartIcon = <CheckIcon />;
    }

    return (
        <Grid item xs={12}>
            <ItemCenter>
                <Typography variant="button" display="block" sx={{ color: warningColor }} noWrap>{warningTitle}</Typography>
                <Typography variant="caption">{warningLabel}
                    <Button
                        style={{marginBottom: -3}}
                        size="small"
                        label="Enable Now"
                        startIcon={buttonStartIcon}
                        onClick={() => mutate()}
                        disabled={buttonDisabled}
                    >
                        {buttonLabel}
                    </Button>
                </Typography>
            </ItemCenter>
        </Grid>
    );
}